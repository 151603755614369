<template>
  <div
    class="bb-select"
    :class="selectOpenedClass"
    v-click-outside="closeSelect"
  >
    <slot
      name="toggle"
      :toggleSelect="toggleSelect"
      :removeSelected="removeSelected"
      :isOpen="isOpen"
    >
      <div class="bb-select-toggle" @click="toggleSelect">
        <span
          v-text="
            selected
              ? selected[selectedTextKey]
              : placeholderHidden
              ? ''
              : placeholderText
          "
          :class="toggleSelectClass"
        ></span>
        <i :class="selectArrowClass" class="text-main"></i>
      </div>
    </slot>
    <div v-if="search && isOpen" class="bb-select-search">
      <div class="bb-select-search-input">
        <i class="fas fa-search"></i>
        <input
          class="bb-input"
          type="text"
          v-model="searchTerm"
          :placeholder="searchPlaceholderText"
          ref="bbSelectSearchInput"
        />
        <i class="fas fa-times-circle" @click="closeSelect"></i>
      </div>
    </div>
    <div :class="optionsClass" v-show="isOpen">
      <div class="bb-select-options-container">
        <div v-show="displayOptions.length === 0" class="no-options">
          {{ noOptionsDescription }}
        </div>
        <perfect-scrollbar
          class="scroll"
          :style="scrollbarHeight"
          v-show="displayOptions.length > 0"
        >
          <div v-for="(option, key) in displayOptions" :key="key">
            <div v-if="option.options">
              <div class="bb-select-option-group-title">{{ option.text }}</div>
              <div
                v-for="option in option.options"
                :key="option.value"
                class="bb-select-option-item"
                @click="optionSelected(option)"
              >
                <slot name="optionGroupItem" :option="option">
                  {{ option.text }}
                </slot>
              </div>
            </div>
            <div v-else>
              <div
                class="bb-select-option-item"
                @click="optionSelected(option)"
              >
                <slot name="optionItem" :option="option">
                  {{ option.text }}
                </slot>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
        <div
          class="bb-select-footer-item"
          v-show="this.$slots['optionsFooter']"
          ref="optionsFooter"
        >
          <slot name="optionsFooter"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BBSelect",
  props: {
    /**
     * Placeholder for select when none of options is. selected.
     * @type {String}
     */
    placeholder: {
      type: String,
      required: false
    },

    /**
     * Hide placeholder
     * @type {Boolean}
     */
    placeholderHidden: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * Array of options for select component.
     * Each option in array needs to be an object which has text and value properties.
     * @type {Array}
     */
    options: {
      type: Array,
      required: true
    },

    /**
     * Placeholder for search in select component if search is enabled.
     * @type {String}
     */
    searchPlaceholder: {
      type: String,
      required: false
    },

    /**
     * Determine if search options is enabled for select component
     * @type {Boolean}
     */
    search: Boolean,

    /**
     * Initial selected option value
     * @type {String|Number}
     */
    value: {
      type: [String, Number],
      required: false
    },

    /**
     * Key of option object from where to get text for selected option
     * @type {String}
     */
    selectedTextKey: {
      type: String,
      required: false,
      default: "text"
    },

    /**
     * String to represent when none option is available
     * @type {String}
     */
    noOptionsText: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      isOpen: false,
      selected: null,
      searchTerm: "",
      scrollbarHeight: {
        height: "100%"
      }
    };
  },
  methods: {
    /**
     * Toggle select component by change isOpen data property
     * @return {void}
     */
    toggleSelect() {
      this.isOpen = !this.isOpen;
      this.$nextTick(function() {
        if (this.search && this.isOpen) {
          this.$refs.bbSelectSearchInput.focus();
        }
      });
    },

    /**
     * Close select component by set isOpen data property to false
     * @return {void}
     */
    closeSelect() {
      this.isOpen = false;
    },

    /**
     * Set selected data property, close select and emit optionSelected and input events
     * @param {Object} option
     * @emits input
     * @emits optionSelected
     */
    optionSelected(option) {
      this.selected = option;
      this.$emit("input", option.value);
      this.closeSelect();
      this.$emit("optionSelected", option);
    },

    /**
     * Set selected data property to null and emit selectedRemoved and input events
     * @emits input
     * @emits selectedRemoved
     */
    removeSelected() {
      this.selected = null;
      this.$emit("input", null);
      this.$emit("selectedRemoved");
    }
  },
  computed: {
    /**
     * Return options to display in select component
     * @return {Array}
     */
    displayOptions() {
      if (this.options.length === 0) {
        return [];
      }
      if (this.options[0].options) {
        let options = [];
        let optionGroups = JSON.parse(JSON.stringify(this.options));
        optionGroups.forEach(optionGroup => {
          optionGroup.options = optionGroup.options.filter(option =>
            option.text.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
          if (optionGroup.options.length > 0) {
            options.push(optionGroup);
          }
        });
        return options;
      }
      return this.options.filter(option =>
        option.text.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },

    /**
     * Return class name for text color in select component toggle div
     * @return {String}
     */
    toggleSelectClass() {
      return this.selected ? "text-main" : "text-muted";
    },

    /**
     * Return class name for icon in select component toggle div
     * @return {String}
     */
    selectArrowClass() {
      return this.isOpen ? "la la-angle-up" : "la la-angle-down";
    },

    /**
     * Return additional class for select component when is opened
     * @return {String}
     */
    selectOpenedClass() {
      return this.isOpen ? "bb-select-opened" : "";
    },

    /**
     * Return class for select component options based on search is enabled
     * @return {String}
     */
    optionsClass() {
      return this.search
        ? "bb-select-options search-enabled"
        : "bb-select-options";
    },

    /**
     * Return text for placeholder
     * @return {String}
     */
    placeholderText() {
      return this.placeholder
        ? this.placeholder
        : this.$t("defaults.select.placeholder");
    },

    /**
     * Return text for search placeholder
     * @return {String}
     */
    searchPlaceholderText() {
      return this.searchPlaceholder
        ? this.searchPlaceholder
        : this.$t("defaults.select.searchPlaceholder");
    },

    /**
     * Return no options description
     */
    noOptionsDescription() {
      return this.noOptionsText
        ? this.noOptionsText
        : this.$t("defaults.select.noOptionsText");
    }
  },
  watch: {
    /**
     * Watch for isOpen data property to set height of options scrollbar
     * @return {void}
     */
    isOpen() {
      this.$nextTick(function() {
        this.scrollbarHeight = {
          height: "calc(100% - " + this.$refs.optionsFooter.offsetHeight + "px)"
        };
      });
    },

    /**
     * Watch for options change
     */
    options: {
      immediate: true,
      handler() {
        if (this.options && this.value) {
          let selected = this.options.find(
            option => option.value === this.value
          );
          if (selected) {
            this.selected = selected;
          }
        }
      }
    },

    /**
     * Watch for value change
     */
    value: {
      immediate: true,
      handler() {
        if (!this.value) {
          this.removeSelected();
        } else {
          if (this.options.length > 0) {
            // let selected = null;
            if (this.options[0].options) {
              this.options.forEach(option => {
                let selected = option.options.find(
                  option => option.value === this.value
                );
                if (selected) {
                  this.selected = selected;
                }
              });
            } else {
              let selected = this.options.find(
                option => option.value === this.value
              );
              if (selected) {
                this.selected = selected;
              } else {
                this.removeSelected();
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    // If value is passed and it is in options set selected to that value
    if (this.value) {
      let selected = this.options.find(option => option.value === this.value);
      this.selected = selected;
    }
  }
};
</script>

<style></style>
