<template>
  <div class="bb-card appointments-list-card">
    <h5 v-if="subject.appointments && subject.appointments.length > 0">
      {{ appointmentsListTitle }}
    </h5>
    <div
      v-if="subject.appointments && subject.appointments.length > 0"
      class="appointments-list"
      style="position: relative"
    >
      <div
        v-for="(appointment, key) in displayedAppointments"
        :key="key"
        class="appointment-item mt-5 mb-5"
      >
        <div class="d-flex">
          <div class="appointment-item-date">
            <span class="month-day">{{ getMonthDay(appointment.date) }}</span>
            <div class="weekday-month-container">
              <span class="weekday-string">{{
                getWeekday(appointment.date)
              }}</span>
              <span class="month-string">{{
                getMonthName(appointment.date)
              }}</span>
            </div>
          </div>
          <div class="appointment-item-description">
            <span
              class="appointment-status"
              :class="getStatusClass(appointment.status)"
              >{{
                appointment.status === "CANCELED"
                  ? $t("appointmentList.status.canceled")
                  : appointment.status === "FINISHED"
                  ? $t("appointmentList.status.finished")
                  : $t("appointmentList.status.active")
              }}</span
            >
            <span class="appointment-service">{{
              appointment.service.name
            }}</span>
            <span class="appointment-time">
              {{ appointment.from + " - " + appointment.to }}
            </span>
            <span class="appointment-employee-title">{{
              $t("calendar.appointment.employee")
            }}</span>
            <span class="appointment-employee">
              {{
                appointment.employee.first_name +
                " " +
                appointment.employee.last_name
              }}
            </span>
          </div>
          <div>
            <span class="appointment-item-price">{{
              appointment.service.price + " RSD"
            }}</span>
          </div>
        </div>
        <div class="appointment-break-row"></div>
        <br />
        <div
          v-if="
            appointment.status == 'CANCELED' || appointment.status == 'FINISHED'
          "
          class="event-action-buttons"
        ></div>
        <div v-else class="event-action-buttons">
          <button
            class="event-cancel-btn"
            @click="cancelAppointment(appointment)"
          >
            {{ $t("calendar.appointment.cancelAppointment") }}
          </button>
          <button
            class="event-edit-btn"
            @click="editAppointmentConcatenate(appointment)"
          >
            {{ $t("calendar.appointment.editAppointment") }}
          </button>
        </div>
      </div>
      <v-pagination
        class="bb-pagination"
        v-model="page"
        :length="pages.length"
        v-if="!paginationDisabled"
      >
      </v-pagination>
    </div>
    <p
      v-if="subject.appointments && subject.appointments.length === 0"
      class="no-appointments-text"
    >
      {{ $t("appointmentList.noAppointmentsLabel") }}
    </p>
    <CancelAppointmentModal ref="cancelAppointmentModal" />
  </div>
</template>

<script>
import CancelAppointmentModal from "@/view/layout/extras/modal/CancelAppointmentModal.vue";
import cancelAppointmentMixin from "@/core/mixins/cancelAppointmentMixin.js";
import appointmentFormMixin from "@/core/mixins/appointmentFormMixin.js";
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "AppointmentsList",
  components: {
    CancelAppointmentModal,
  },
  mixins: [cancelAppointmentMixin, appointmentFormMixin],
  props: {
    /**
     * Subject object
     * @type {Object}
     */
    subject: {
      type: Object,
      required: false,
      default: null,
    },

    /**
     * Determine if pagination needs to be disabled
     * @type {Boolean}
     */
    paginationDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Appointment list title
     * @type {String}
     */
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      page: 1,
      perPage: 3,
      pages: [],
      eventClick: this.handleEventClick,
    };
  },
  methods: {
    /**
     * Return day of week as string
     * @param {String} dateString
     * @return {String}
     */
    getWeekday(dateString) {
      let date = Date.parse(dateString);
      return moment(date).locale(i18nService.getActiveLanguage()).format("ddd");
    },

    /**
     * Return day of month as string
     * @param {String} dateString
     * @return {String}
     */
    getMonthDay(dateString) {
      let date = Date.parse(dateString);
      return moment(date).format("D");
    },

    /**
     * Return month as 3 characters string
     * @param {String} dateString
     * @return {String}
     */
    getMonthName(dateString) {
      let date = Date.parse(dateString);
      return moment(date)
        .locale(i18nService.getActiveLanguage())
        .format("MMM")
        .toUpperCase();
    },

    /**
     * Return class name for given status
     * @param {String} status
     * @return {String}
     */
    getStatusClass(status) {
      if (status.toLowerCase() === "canceled") {
        return "danger";
      }
      return "success";
    },

    /**
     * Set pages data property
     * @return {void}
     */
    setPages() {
      let numOfPages = Math.ceil(
        this.subject.appointments.length / this.perPage
      );
      for (let index = 1; index <= numOfPages; index++) {
        this.pages.push(index);
      }
    },

    /**
     * Paginate appointmets
     * @param {Array} appointments
     * @return {Array}
     */
    paginate(appointments) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return appointments.slice(from, to);
    },

    /**
     * Concatenate subject to appointment
     */
    editAppointmentConcatenate(appointment) {
      appointment.subject = this.subject;
      this.editAppointment(appointment);
    },
  },
  computed: {
    /**
     * Return paginated appointments
     * @return {Array}
     */
    displayedAppointments() {
      return this.subject && this.subject.appointments
        ? this.paginate(this.subject.appointments)
        : [];
    },

    /**
     * Return component title
     * @return {String}
     */
    appointmentsListTitle() {
      if (this.title) {
        return this.title;
      }
      return this.paginationDisabled
        ? this.$t("appointmentList.title") +
            "(" +
            this.subject.appointmentCount +
            ")"
        : this.$t("appointmentList.title");
    },
  },
  watch: {
    /**
     * Watch for subject prop
     */
    subject: {
      immediate: true,
      handler(subject) {
        if (subject) {
          if (subject.appointments) {
            this.setPages();
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/appointments-list.scss";
</style>
