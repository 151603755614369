<template>
  <div class="bb-switch-container">
    <span v-if="frontLabel" class="bb-switch-label" style="margin-right: 10px; !important">{{ frontLabel }}</span>
    <b-form-checkbox
      switch
      size="lg"
      v-model="passedValue"
      @input="onSwitch($event)"
      :disabled="disabled"
      class="bb-switch"
    ></b-form-checkbox>
    <span v-if="label" class="bb-switch-label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "BBSwitch",
  props: {
    /**
     * Initial value for switch
     * @type {Boolean|Number}
     */
    value: {
      type: [Boolean, Number],
      required: true
    },

    /**
     * Label for switch
     * @type {String}
     */
    label: {
      type: String,
      required: false
    },

    /**
     * Front label for switch
     * @type {String}
     */
     frontLabel: {
      type: String,
      required: false
    },

    /**
     * Disabled property to prevent value change
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Emits input event for new value for switch
     * @param {Boolean} newValue
     * @emits input
     */
    onSwitch(newValue) {
      this.$emit("input", newValue);
    }
  },
  computed: {
    /**
     * Represent initial value passed trought props and set new value
     */
    passedValue: {
      get() {
        return Boolean(this.value);
      },
      set(newValue) {
        return Boolean(newValue);
      }
    }
  }
};
</script>

<style></style>
