import {
  INIT_FORM,
  SUBMIT_FORM,
  SET_FORM_FIELD,
  UNSET_FORM
} from "@/core/services/store/form.module";

export default {
  data() {
    return {
      /**
       * Form object
       * @type {Object}
       */
      form: {
        key: undefined,
        mode: undefined,
        path: undefined,
        fields: {},
        errors: {}
      }
    };
  },

  computed: {
    /**
     * Get the form state from store
     * @return {Object}
     */
    formState() {
      return this.$store.getters.getForm(this.form.key);
    }
  },

  methods: {
    /**
     * Init the form
     * @return {void}
     */
    initForm() {
      this.$store.dispatch(INIT_FORM, this.form);
    },

    /**
     * Set form fields to form state
     * @return {void}
     */
    setFormFields() {
      for (const [key, value] of Object.entries(this.form.fields)) {
        this.$store.dispatch(SET_FORM_FIELD, {
          formKey: this.form.key,
          fieldName: key,
          value: value
        });
      }
    },

    /**
     * Submit the form
     * @return {void}
     */
    submitForm() {
      this.setFormFields();
      return this.$store.dispatch(SUBMIT_FORM, this.form.key);
    },

    /**
     * Remove form from store
     * @return {void}
     */
    removeForm() {
      this.$store.dispatch(UNSET_FORM, this.form.key);
    }
  },
  watch: {
    /**
     * Watch for form state change
     */
    "$store.state.form.forms": {
      immediate: true,
      deep: true,
      handler() {
        if (this.formState) {
          const { fields, errors, mode, helpers } = this.formState;
          this.form.fields = fields;
          this.form.errors = errors;
          this.form.mode = mode;
          this.form.helpers = helpers;
        }
      }
    }
  }
};
