import subjectForm from "@/core/services/forms/subjectForm.js";
import { INIT_FORM } from "@/core/services/store/form.module.js";

export default {
  methods: {
    /**
     * Init subject form with default values from subjectForm object and open subject form element
     * @param {Object|null} fields
     * @return {void}
     */
    createSubject(fields = null) {
      let form = JSON.parse(JSON.stringify(subjectForm));
      if (fields) {
        for (const [key, value] of Object.entries(fields)) {
          form.fields[key] = value;
        }
      }
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.subjectFormModal) {
        this.$refs.subjectFormModal.show();
      }
    },

    /**
     * Init subject form with edit mode and open subject form element
     * @param {Object} subject
     * @return {void}
     */
    editSubject(subject) {
      let form = JSON.parse(JSON.stringify(subjectForm));
      form.mode = "edit";
      form.path = form.path + "/" + subject.id;
      for (const [key, value] of Object.entries(subject)) {
        form.fields[key] = value;
      }
      form.fields._method = "PUT";
      form.fields = this.updateSubjectFormFields(form.fields, subject);
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.subjectFormModal) {
        this.$refs.subjectFormModal.show();
      }
    },

    /**
     * Update form fields object
     * @param {Object} formFields
     * @param {Object} subject
     * @return {Object}
     */
     updateSubjectFormFields(formFields, subject) {
      formFields.clientTypeSwitch = 
        subject.clientType == "Defendant" ? true : false;
      if (subject.otherClient) {
        formFields.firstName = subject.otherClient.first_name;
        formFields.lastName = subject.otherClient.last_name;
        formFields.phone = subject.otherClient.phone;
      }

      return formFields;
    }
  }
};
