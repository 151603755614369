<template>
  <div class="bb-dropdown" v-click-outside="closeDropdown">
    <div class="bb-dropdown-toggle" @click="toggleDropdown">
      <slot name="toggle" :toggleDropdown="toggleDropdown" :isOpen="isOpen">
        <span>{{ toggleBtnText }}</span>
      </slot>
    </div>
    <div class="bb-dropdown-content" :class="positionClass" v-show="isOpen">
      <div class="bb-dropdown-items-container">
        <slot name="items" :closeDropdown="closeDropdown">
          <span>{{ $t("defaults.dropdown.description") }}</span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BBDropdown",
  props: {
    /**
     * Text to show on default dropdown toggle slot
     * @type {String}
     */
    toggleText: {
      type: String,
      required: false
    },

    /**
     * Determine position of dropdown by class name
     * @type {String}
     */
    positionClass: {
      type: String,
      required: false,
      default: "left"
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    /**
     * Toggle dropdown, change isOpen data property
     * @return {void}
     */
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    /**
     * Close dropdown, change isOpen data property to false
     * @return {void}
     */
    closeDropdown() {
      this.isOpen = false;
    }
  },
  computed: {
    /**
     * Return text for toggle button
     * @return {String}
     */
    toggleBtnText() {
      return this.toggleText
        ? this.toggleText
        : this.$t("defaults.dropdown.toggleText");
    }
  }
};
</script>

<style></style>
