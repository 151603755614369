const subjectForm = {
    /**
     * Client form key
     * @type {String}
     */
    key: "subjectForm",
  
    /**
     * Client form fields object
     * @type {Object}
     */
    fields: {
      courtName: "",
      courtNumber: "",
      subjectNumber: "",
      otherClient: "",
      clientId: null,
      clientType: "",
      clientTypeSwitch: true
    },
  
    /**
     * Client form helpers object
     * @type {Object}
     */
    helpers: {
      otherClient: {
        first_name: null,
        last_name: null,
        phone: null
      },
    },
  
    /**
     * Client form errors object
     * @type {Object}
     */
    errors: {},
  
    /**
     * Client form mode
     * @type {String}
     */
    mode: "create",
  
    /**
     * Client form path
     * @type {String}
     */
    path: "/api/b2b/subjects"
  };
  
  export default subjectForm;
  