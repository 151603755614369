<template>
  <b-modal id="subject_form_modal" ref="bvModal" :no-close-on-backdrop="true">
    <template v-slot:modal-header="{ close }">
      <div class="modal-header-buttons-container">
        <div class="placeholder"></div>
        <h2>{{ modalTitle }}</h2>
        <button
          class="close-modal-btn mr-7"
          id="subject_form_modal_close_btn"
          @click="close()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </template>
    <div class="modal-body-section">
      <div class="modal-inputs-holder">
        <div class="form-groups-container">
          <div class="bb-form-group">
            <label>{{ $t("subjectFormModal.subjectNumber.label") }}</label>
            <input
              class="bb-input"
              type="number"
              v-model="form.fields.subjectNumber"
            />
            <div class="bb-invalid-feedback" v-show="form.errors.subjectNumber">
              {{ $t("subjectFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{ $t("subjectFormModal.courtNumber.label") }}</label>
            <input
              class="bb-input"
              type="number"
              v-model="form.fields.courtNumber"
            />
            <div class="bb-invalid-feedback" v-show="form.errors.lastName">
              {{ $t("subjectFormModal.validation.error") }}
            </div>
          </div>
        </div>
        <div class="bb-form-group">
          <label>{{ $t("subjectFormModal.type.label") }}</label>
          <BBSelect
            v-model="form.fields.type"
            :options="typeOptions"
          ></BBSelect>
          <div class="bb-invalid-feedback" v-show="form.errors.type">
            {{ $t("subjectFormModal.validation.error") }}
          </div>
        </div>
        <div class="bb-form-group">
          <label>{{ $t("subjectFormModal.courtName.label") }}</label>
          <div class="birthday-inputs">
            <BBSelect
              v-model="form.fields.courtName"
              :options="courtOptions"
              :search="true"
              :searchPlaceholder="
                $t('subjectFormModal.subjectInput.searchPlaceholder')
              "
            ></BBSelect>
            <div class="bb-invalid-feedback" v-show="form.errors.courtName">
              {{ $t("subjectFormModal.validation.error") }}
            </div>
          </div>
        </div>
        <div class="bb-form-group client-select">
          <label>{{
            $t("subjectFormModal.clientInput.label")
          }}</label>
          <BBSelect
            :options="clientsAsOptions"
            v-model="form.fields.clientId"
            :search="true"
            :placeholder="$t('subjectFormModal.clientInput.placeholder')"
            :searchPlaceholder="
              $t('subjectFormModal.clientInput.searchPlaceholder')
            "
            :noOptionsText="$t('subjectFormModal.clientInput.noResultsText')"
          >
            <template v-slot:optionItem="{ option }">
              <div class="badge-option-container">
                <div class="badge-option">
                  <div class="bb-badge">{{ option.initials }}</div>
                  <div class="badge-option-text">
                    <span>{{ option.text }}</span>
                    <small>{{ option.description }}</small>
                  </div>
                </div>
              </div>
            </template>
          </BBSelect>
          <div class="bb-invalid-feedback" v-show="form.errors.clientId">
            {{ form.errors.clientId }}
          </div>
        </div>
        <div v-show="form.fields.type == 'Litigation'" >
          <div class="form-groups-container">
            <div class="bb-form-group">
              <label>{{ $t("subjectFormModal.clientType.label") }}</label>
              <BBSwitch
                v-model="form.fields.clientTypeSwitch"
                :label="$t('subjectFormModal.clientType.options.defendant')"
                :frontLabel="$t('subjectFormModal.clientType.options.prosecutor')"
              />
              <div
                class="bb-invalid-feedback"
                v-show="form.errors.displayNotes"
              >
                {{ $t("subjectFormModal.validation.error") }}
              </div>
            </div>
          </div>
          <div 
            class="bb-form-group"  
            style="margin-bottom: -10px !important;"
          >
            <label>{{ $t("subjectFormModal.otherClient.label") }}</label>
          </div>
          <div class="form-groups-container">
            <div class="bb-form-group">
              <label>{{ $t("subjectFormModal.otherClient.firstNameInput") }}</label>
              <input
                class="bb-input"
                type="text"
                v-model="form.fields.firstName"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.otherClientFirstName">
                {{ $t("subjectFormModal.validation.error") }}
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{ $t("subjectFormModal.otherClient.lastNameInput") }}</label>
              <input
                class="bb-input"
                type="text"
                v-model="form.fields.lastName"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.otherClientLastName">
                {{ $t("subjectFormModal.validation.error") }}
              </div>
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{ $t("subjectFormModal.otherClient.mobilePhoneInput") }}</label>
            <input
              class="bb-input"
              type="text"
              v-model="form.fields.phone"
            />
            <div class="bb-invalid-feedback" v-show="form.errors.otherClientPhone">
              {{ $t("subjectFormModal.validation.error") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer="{ close }">
      <div class="footer-left" v-show="footerLeftVisible">
        <button
          class="bb-btn btn-danger"
          v-show="form.mode === 'edit'"
          @click="deleteSubject()"
        >
          {{ $t("subjectFormModal.deleteBtn") }}
        </button>
      </div>
      <div class="footer-right">
        <button class="bb-btn btn-outline" @click="close()">
          {{ $t("subjectFormModal.closeBtn") }}
        </button>
        <button class="bb-btn btn-success" @click="submit()">
          {{ $t("subjectFormModal.saveBtn") }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_SUBJECTS } from "@/core/services/store/subjects.module";
import { GET_CLIENTS } from "@/core/services/store/clients.module";
import { OPEN_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { EventBus } from "@/core/services/event-bus.js";
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import subjectForm from "@/core/services/forms/subjectForm.js";
import formMixin from "@/core/mixins/formMixin.js";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import BBSwitch from "@/view/content/BBForm/BBSwitch.vue";
import moment from "moment";

export default {
  name: "SubjectFormModal",
  mixins: [formMixin],
  components: {
    BBSelect,
    BBSwitch
  },
  data() {
    return {
      typeOptions: [
        {
          value: "Culpability",
          text: this.$t("subjectFormModal.type.options.culpability"),
        },
        {
          value: "Litigation",
          text: this.$t("subjectFormModal.type.options.litigation"),
        }
      ],
    };
  },
  beforeMount() {
    // Get form object from subjectForm service
    this.form = JSON.parse(JSON.stringify(subjectForm));
    // Set moment locale
    moment.locale(i18nService.getActiveLanguage());
  },
  methods: {
    /**
     * Show the modal
     * @return {void}
     */
    show() {
      this.$refs.bvModal.show();
    },

    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#subject_form_modal_close_btn").click();
    },

    /**
     * Open offcanvas element by given offcanvas element id
     * @param {String} elementId
     * @return {void}
     */
      openOffcanvas(elementId) {
        document.querySelector("#" + elementId + "_toggle").click();
        this.$store.dispatch(OPEN_ELEMENT, elementId);
      },

    /**
     * Update form fields from form helpers
     * @return {void}
     */
     updateFormFields() {
      if (this.form.fields.clientTypeSwitch == 0) {
        this.form.fields.clientType = "Prosecutor";
      } else {
        this.form.fields.clientType = "Defendant";
      }

      if (
        this.form.fields.firstName &&
        this.form.fields.lastName &&
        this.form.fields.phone
      ) {
        this.form.fields.otherClient = {
          'first_name': this.form.fields.firstName,
          'last_name': this.form.fields.lastName,
          'phone': this.form.fields.phone,
        }  
      }
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.updateFormFields();
      this.submitForm()
        .then((response) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null,
          });
          this.closeModal();
          if (this.$route.name === "subject") {
            this.$emit("subjectUpdated");
          } else {
            this.$store.dispatch(GET_SUBJECTS);
          }
        })
        .catch((error) =>{
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message],
          })
        });
    },

    /**
     * Delete the subject
     * @return {void}
     */
    deleteSubject() {
      let subject = this.$store.getters.getSubject(this.form.fields.id);

      EventBus.$emit("deletingSubject", {
        subject: subject,
        successCallback: function () {
          document.querySelector("#subject_form_modal_close_btn").click();
          this.$store.dispatch(GET_CLIENTS);
        },
      });
    },

    /**
     * Open MobileClientSelect element and add form key attribute to element
     * @return {void}
     */
    selectClient() {
      document
        .querySelector("#mobile_client_select")
        .setAttribute("form-key", "subjectFormModal");
      this.openOffcanvas("mobile_client_select");
    },

    /**
     * Remove selected client
     * @return {void}
     */
    removeSelectedClient() {
      this.form.fields.clientId = null;
    },
  },
  computed: {
    ...mapGetters(["clientsAsOptions", "currentUser"]),

    /**
     * Return array of courts options
     * @return {Array}
     */
    courtOptions() {
      let courtOptions = [];
      let courts = require('@/assets/data/courts.json');
      
      for(let i = 0; i < courts.length; i++) {
        let option = {
          text: courts[i],
          value: courts[i],
        };
        courtOptions.push(option);
      }
      return courtOptions;
    },

    /**
     * Return selected client object
     * @return {Object|null}
     */
    selectedClient() {
      if (this.form.fields.clientId) {
        return this.$store.getters.getClient(this.form.fields.clientId);
      }
      return null;
    },

    /**
     * Return modal title
     * @return {String}
     */
    modalTitle() {
      return this.form.mode === "edit"
        ? this.$t("subjectFormModal.editTitle")
        : this.$t("subjectFormModal.createTitle");
    },

    /**
     * Determine if left footer section is visible
     * @return {Boolean}
     */
    footerLeftVisible() {
      if (window.innerWidth < 992) {
        return this.form.mode === "edit";
      }
      return true;
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/subject-form-modal.scss";
</style>
